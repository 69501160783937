import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Header from '../../general/navigation/Header';

const LoginPage = () => {
  const {isAuthenticated, login} = useContext(AuthContext);

  if(isAuthenticated) {
    window.location.replace('/#/');
  }

  return (
    <div>
    <Header />
      <div className="d-flex justify-content-center mt-10">
        {
          !isAuthenticated && <button className="btn btn-primary mt-5" onClick={()=> login()}>Sign In</button>
        }
      </div>
    </div>
  )
}

export default LoginPage;