import axios from 'axios';
import {UserAgentApplication} from 'msal';
import azure from './azure';

export const api = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_BASE_URL : '/api',
  withCredentials: true,
});

if (process.env.REACT_APP_SSO_ENABLED !== 'false') {
  const msalInstance = new UserAgentApplication({
    auth: {
      clientId: azure.appId,
      authority: azure.ssoURL,
      redirectUri: azure.redirectUri,
    }
  });

  api.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 403) {
        window.location.replace('/#/login');
        window.location.reload();

        if (msalInstance.getAccount()) {
          msalInstance.logout();
        }
      }
    }
  );
} else {
  api.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 403) {
        window.location.replace('/#/login');
        window.location.reload();
      }
    }
  );
}
