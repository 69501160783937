import { createContext, useState, useEffect, useCallback, useRef } from "react";
import { UserAgentApplication } from "msal";
import azure from "../azure";
import { loginUser } from "../services/UserService";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState({});
  const [error, setError] = useState(null);
  let msalInstance = useRef(null);

  const initialiseSSO = useCallback(() => {
    msalInstance.current = new UserAgentApplication({
      auth: {
        clientId: azure.appId,
        authority: azure.ssoURL,
        redirectUri: azure.redirectUri,
      },
    });
  }, []);

  useEffect(() => {
    initialiseSSO();
  }, [initialiseSSO]);

  const login = async () => {
    if (process.env.REACT_APP_SSO_ENABLED !== "false") {
      await msalInstance.current
        .loginPopup({ scopes: azure.scopes })
        .then(async (response) => {
          await loginUser(response);
          setIsAuthenticated(true);
          setUser(response.account);
          setError(null);
          window.location.replace("/#/");
        })
        .catch((error) => {
          setIsAuthenticated(false);
          setUser({});
          setError(error);
        });
    } else {
      const user = {
        account: {
          userName: "userTest",
        },
      };
      loginUser(user).then(() => {
        setIsAuthenticated(true);
        setUser(user.account);
        setError(null);
        window.location.replace("/#/");
      });
    }
  };
  return (
    <AuthContext.Provider value={{ isAuthenticated, user, error, login }}>
      {children}
    </AuthContext.Provider>
  );
};
