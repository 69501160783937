import React, { Fragment, useContext, useEffect, useState } from 'react';
import Header from '../../general/navigation/Header';
import { CompanyContext } from '../../context/CompanyContext';
import { AuthContext } from '../../context/AuthContext.js';
import { CountryContext } from '../../context/CountryContext';
import { fetchCompanies } from '../../services/CompanyService';
import { fetchCountries } from '../../services/CountryService';
import Loading from '../../general/components/Loading.js';
import {Link} from'react-router-dom';
import Select from 'react-select';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast, Bounce } from "react-toastify";
import Cookies from 'js-cookie';

const LandingPage = () => {
  const {isAuthenticated} = useContext(AuthContext);
  const [companyState, companyDispatch] = useContext(CompanyContext);
  const [countryState, countryDispatch] = useContext(CountryContext);
  const [isDoneSelecting, setDoneSelecting] = useState(false);
  const [isCountrySelected, setCountrySelected] = useState(isAuthenticated);

  useEffect(() => {
    // Check if there are no companies or countries in the context

    if (companyState.companies.length <= 0) {
      fetchCompanyData();
    }

    if (countryState.countries.length <= 0) {
      fetchCountryData();
    }

    if (isAuthenticated && !sessionStorage.getItem('toastShown')) {
      const lastLogin = Cookies.get('lastLogin');
      const formatedDate = new Date(lastLogin).toLocaleDateString();
      const formatedTime = new Date(lastLogin).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
      toast.warn(`Last login : ${formatedDate} / ${formatedTime} `, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          sessionStorage.setItem('toastShown', 'true');
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Returns the list of countries to appear as options in the dropdown.
   */
  const returnCountries = () => {
    const options = [];
    for (const country of countryState.countries) {
      options.push({value: (country), label: (country.name)})
    }
    return options;
  }

  /**
   * Returns the list of companies to appear as options in the dropdown.
   */
  const returnCompanies = () => {
    const options = [];
    for (const company of companyState.companies) {
      options.push({value: (company), label: (company.name)})
    }
    return options;
  }

  const fetchCountryData = async () => {
    const countriesResponse = await fetchCountries();

    if (countriesResponse) {
      countryDispatch({
        type: 'FETCH_COUNTRIES',
        countries: countriesResponse,
      });
    }
  };

  const fetchCompanyData = async () => {
    const companiesResponse = await fetchCompanies();

    if (companiesResponse) {
      companyDispatch({
        type: 'FETCH_COMPANIES',
        companies: companiesResponse,
      });
    }
  };

  /**
   * Sets the current selected company to be the item which was selected from the dropdown.
   * @param {object} event passed in upon change in company select dropdown.
   */
  const handleSelectCompany = (event) => {
    // Given the id of the selected company from dropdown, find the selected company from the list of companies in the company state/context.
    const selectedCompany = event.value;

    // Set the current company state to be the selected company.
    companyDispatch({
      type: 'SELECT_COMPANY',
      selectedCompany: selectedCompany,
    });

    // For landing page, only set the company id if no country has been selected. This prevents both a country and company id being in local storage when only one is necessary.
    if (!localStorage.getItem('countryId')) {
      localStorage.setItem('companyId', selectedCompany.id);
    }
    setDoneSelecting(true);
  };

  /**
   * Sets the current selected country to be the item which was selected from the dropdown.
   * @param {object} event passed in upon change in country select dropdown.
   */
  const handleSelectCountry = (event) => {
    localStorage.removeItem('regionId');
    localStorage.removeItem('companyId');
    // Find the selected country from the list of countries in the country context.
    const selectedCountry = event.value;

    // Set the country state to be selected country.
    countryDispatch({
      type: 'SELECT_COUNTRY',
      selectedCountry: selectedCountry,
    });

    localStorage.setItem('countryId', selectedCountry.id);

    // If there is a region in the selected country, set it in the local storage.
    if (selectedCountry.regions.length === 1) {
      localStorage.setItem('regionId', selectedCountry.regions[0].id);
      setDoneSelecting(true);
      setCountrySelected(true);
    } else {
      setDoneSelecting(false);
    }
  };

  useEffect(() => {
    localStorage.removeItem('companyId');
    localStorage.removeItem('countryId');
    localStorage.removeItem('regionId');
  }, []);

  return (
    companyState.companies.length <= 0
    ? <Loading />
    : <Fragment>
        <Header showClientNavigation={false} showImmigrationNavigation={false} displayName={null} link={null}/>
        <ToastContainer 
            autoClose={4000} 
            position="top-right"
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover={false}
            theme="light"
            style={{ top: '60px' }}
          />
        <div className="landing-page-container">
          <div className="landing-page-dropdown-position">
            <div>
            <div className="label">COUNTRY</div>
            <Select 
              className="landing-page-select" 
              onChange={(event) => handleSelectCountry(event)} 
              options={returnCountries()}
            />
            </div>
          </div>
          <div>
            <div className="align-divider">
              <div className="line-6"></div><div className="or">OR</div><div className="line-6"></div>
            </div>
          </div>
          <div className="landing-page-dropdown-position">
            <div>
              <div className="label">CLIENT</div>
              <Select 
                className="landing-page-select" 
                onChange={(event) => handleSelectCompany(event)} 
                options={returnCompanies()}
              />
            </div>
          </div>
        </div>
        <div className="continue-link-position">        
          {isDoneSelecting &&
            <div> 
              {/* Go to the selecttedCountry Requirements when both a country and a company are selected on the landing page. */}
              {isCountrySelected 
                ? <Link to={`/countries/${countryState.selectedCountry.id}/requirements`}>Continue</Link> 
                : <Link to={`/companies/${companyState.selectedCompany.id}/job-positions/create`}>Continue</Link>}
            </div>
          }
        </div>
    </Fragment>
  );
};

export default LandingPage;
